<template>
	<div class="download">
		<SubHeader title="Download" subtitle="各種ダウンロード" />

		<div class="container">
			<div class="row">
				<div class="col-lg-4 mb-5">
					<h2>登録・申請関連<small>（2024.3.5&nbsp;最終更新）</small></h2>
					<h3><span>●</span>会員</h3>
					<dl class="row">
						<dt class="col-sm-7">個人会員 新規入会申込書</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/member_entry.pdf"
								target="_blank"
								>PDF</a
							>
							<a
								class="btn btn-download"
								href="/pdf/member_entry.xlsx"
								target="_blank"
								>XLS</a
							>
						</dd>
						<dt class="col-sm-7">
							個人会員 登録申込書【継続】<br /><small
								>※登録済会員の更新はこちら</small
							>
						</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/2024/member_entry_2024.pdf"
								target="_blank"
								>PDF</a
							>
							<a
								class="btn btn-download"
								href="/pdf/2024/member_entry_2024.xlsx"
								target="_blank"
								>XLS</a
							>
						</dd>
					</dl>

					<h3><span>●</span>チーム</h3>
					<dl class="row">
						<dt class="col-sm-7">チーム登録用紙</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/team_entry_sheet.pdf"
								target="_blank"
								>PDF</a
							>
							<a
								class="btn btn-download"
								href="/pdf/team_entry_sheet.xls"
								target="_blank"
								>XLS</a
							>
						</dd>
					</dl>
				</div>

				<div class="col-lg-4 mb-5">
					<h2>規約・マナー関連</h2>
					<ul class="list">
						<li>
							<a href="/pdf/kiyaku.pdf" target="_blank"
								>ODO規約（最終改定：平成30年5月18日）</a
							>
						</li>
						<li>
							<a href="/pdf/odo_rule_20240501.pdf" target="_blank"
								>競技規則（最終改定：令和6年5月1日）</a
							>
						</li>
						<li>
							<a href="/pdf/odo_manner_20240501.pdf" target="_blank"
								>マナーブック（最終改定：令和6年5月1日）</a
							>
						</li>
					</ul>
				</div>
				<div class="col-lg-4 mb-5">
					<h2>スコア・リザルト</h2>
					<h3><span>●</span>ODOリーグ</h3>
					<dl class="row">
						<dt class="col-sm-7">リザルト＆スコアシート</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/odo_result_score_sheet.xls"
								target="_blank"
								>XLS</a
							>
						</dd>
						<dt class="col-sm-7">HOMEリザルト＆オーダー</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/odo_result_home.pdf"
								target="_blank"
								>PDF</a
							>
						</dd>
						<dt class="col-sm-7">AWAYリザルト＆オーダー</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/odo_result_away.pdf"
								target="_blank"
								>PDF</a
							>
						</dd>
						<dt class="col-sm-7">スコアシート（1001）</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/odo_score_sheet_501.pdf"
								target="_blank"
								>PDF</a
							>
						</dd>
						<dt class="col-sm-7">スコアシート（501）</dt>
						<dd class="col-sm-5 text-right">
							<a
								class="btn btn-download"
								href="/pdf/odo_score_sheet_1001.pdf"
								target="_blank"
								>PDF</a
							>
						</dd>
					</dl>
					<h3><span>●</span>ペンタスロン</h3>
					<ul class="list">
						<li>
							<a href="./pdf/pentathlon/pentathlon2020.pdf" target="_blank"
								>ペンタスロンシート（リザルト・ポイント表・ルール・リザルト見本）</a
							>
						</li>
						<!--<li>
							<a href="./pdf/PentathlonPointList.pdf" target="_blank">ペンタスロン ポイントリスト</a>
						</li>
						<li>
							<a href="./pdf/PentathlonResultForm.pdf" target="_blank">ペンタスロン リザルトフォーム</a>
						</li>-->
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";

export default {
	name: "download",
	components: {
		SubHeader,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}
h3 {
	font-size: 1rem;
	font-weight: bold;
	margin: 1rem 0;
	span {
		color: $primary-color;
		margin-right: 0.4rem;
	}
}
a {
	text-decoration: underline;
}
dt {
	font-weight: normal;
}
li {
	margin-bottom: 0.4rem;
}
ul ul {
	margin-left: 1rem;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.btn-download {
	font-size: 0.7rem;
	color: #333;
	border: none;
	margin-left: 0.4rem;
	background: $primary-color;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #333;
		background-color: $secondary-color;
	}
	&:active {
		color: #333;
		background-color: $secondary-color;
	}
}
</style>
